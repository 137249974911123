<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <px-listagem-compra-filtros @change="emitChange"/>
      <px-search-text label="Busque por fornecedor, código ou oc" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="emitChange"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="emitChange">
    </px-line-filter>
  </div>
</template>

<script>
import ListagemCompraFiltros from './ListagemCompraFiltros.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODAS',
          description: 'Todas',
          value: null,
        },
        {
          id: 'ABERTAS',
          description: 'Abertas',
          value: ['PENDENTE_COMPRADOR', 'PENDENTE_FORNECEDOR'],
        },
        {
          id: 'ELABORACAO',
          description: 'Em elaboração',
          value: ['ELABORACAO'],
        },
        {
          id: 'CANCELADAS',
          description: 'Canceladas',
          value: ['CANCELADA', 'RECUSADA'],
        },
        {
          id: 'FINALIZADAS',
          description: 'Finalizadas',
          value: ['APROVADA'],
        },
      ],
    };
  },
  computed: {
    selectedStatus: {
      get() {
        return this.$store.getters['listagemCompraGestao/selectedStatus'];
      },
      set(selectedStatus) {
        this.$store.dispatch('listagemCompraGestao/selectedStatus', selectedStatus);
      },
    },
    searchText: {
      get() {
        return this.$store.getters['listagemCompraGestao/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompraGestao/searchText', searchText);
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
  created() {
    const initialStatus = this.statusOptions.find((status) => status.id === 'ELABORACAO');
    this.selectedStatus = initialStatus;
    this.emitChange();
  },
  components: {
    pxListagemCompraFiltros: ListagemCompraFiltros,
  },
};
</script>
