import {
  SET_SEARCH_TEXT,
  SET_COMPRADOR,
  SET_SELECTED_STATUS,
  SET_DATA_ENTRADA_INICIAL,
  SET_DATA_ENTRADA_FINAL,
  SET_PRAZO_RESPOSTA_INICIAL,
  SET_PRAZO_RESPOSTA_FINAL,
  SET_STATUS,
} from './mutations.type';

export default {
  [SET_SEARCH_TEXT](state, searchText) {
    state.searchText = searchText;
  },
  [SET_SELECTED_STATUS](state, selectedStatus) {
    state.selectedStatus = selectedStatus;
  },
  [SET_COMPRADOR](state, comprador) {
    state.comprador = comprador;
  },
  [SET_DATA_ENTRADA_INICIAL](state, dataEntradaInicial) {
    state.dataEntradaInicial = dataEntradaInicial;
  },
  [SET_DATA_ENTRADA_FINAL](state, dataEntradaFinal) {
    state.dataEntradaFinal = dataEntradaFinal;
  },
  [SET_PRAZO_RESPOSTA_INICIAL](state, prazoRespostaInicial) {
    state.prazoRespostaInicial = prazoRespostaInicial;
  },
  [SET_PRAZO_RESPOSTA_FINAL](state, prazoRespostaFinal) {
    state.prazoRespostaFinal = prazoRespostaFinal;
  },
  [SET_STATUS](state, status) {
    state.status = status;
  },
};
