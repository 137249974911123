import axios from 'axios';

const getUsuarios = (company, params) => axios.get(`/user/admin/company/${company}/users`, {
  params,
});
const save = (comprador, user) => axios.post(`/user/admin/comprador/${comprador}/user`, user);
const saveFornecedor = (idFornecedor, user) => axios.post(`/fornecedor/${idFornecedor}/user`, user);
const inativar = (id, data) => axios.put(`/fornecedor/user/${id}/situacao`, data);
const update = (id, user) => axios.put(`/user/${id}`, user);

const create = (companyId, data) => axios.post(`user/admin/company/${companyId}/user`, data);

const vincularUsuario = (data) => axios.post('user/accesses', data);

const removerUserAccess = (idUserAccess) => axios.delete(`user/accesses/${idUserAccess}`);

export default {
  getUsuarios,
  save,
  saveFornecedor,
  vincularUsuario,
  removerUserAccess,
  inativar,
  update,
  create,
};
