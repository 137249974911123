<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="450"
    max-width="600"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon
                 class="mr-2"
                 :color="iconColor"
                 v-on="{ ...onTooltip, ...on }"
                 @click.stop.prevent=""
          >
            <v-icon>mdi-filter-outline</v-icon>
          </v-btn>
        </template>
        <span>Filtrar por</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text class="text--primary">
        <v-row dense class="mt-1">
          <v-col cols="12" sm="6">
            <px-date-picker dense
                            input-class="rounded-tag-small"
                            label="Data Entrada Inicial"
                            v-model="localDataEntradaInicial"/>
          </v-col>
          <v-col cols="12" sm="6">
            <px-date-picker dense
                            input-class="rounded-tag-small"
                            label="Data Entrada Final"
                            v-model="localDataEntradaFinal"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <px-autocomplete-comprador-multiple
              required
              dense
              class="rounded-tag-small"
              v-model="localComprador"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer/>
        <v-btn text class="normal-btn-text" @click="clearFilters">
          Limpar filtros
        </v-btn>
        <v-btn color="primary"
               class="normal-btn-text rounded-tag-small elevation-0"
               @click="emitChange">
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    menuOpened: false,
    localComprador: [],
    localDataEntradaInicial: null,
    localDataEntradaFinal: null,
  }),
  computed: {
    ...mapGetters('listagemCompraGestao', ['hasCustomFilters']),
    iconColor() {
      return this.hasCustomFilters ? 'primary' : '';
    },
    comprador: {
      get() {
        return this.$store.getters['listagemCompraGestao/comprador'];
      },
      set(comprador) {
        this.$store.dispatch('listagemCompraGestao/comprador', comprador);
      },
    },
    dataEntradaInicial: {
      get() {
        return this.$store.getters['listagemCompraGestao/dataEntradaInicial'];
      },
      set(dataEntradaInicial) {
        this.$store.dispatch('listagemCompraGestao/dataEntradaInicial', dataEntradaInicial);
      },
    },
    dataEntradaFinal: {
      get() {
        return this.$store.getters['listagemCompraGestao/dataEntradaFinal'];
      },
      set(dataEntradaFinal) {
        this.$store.dispatch('listagemCompraGestao/dataEntradaFinal', dataEntradaFinal);
      },
    },
  },
  methods: {
    emitChange() {
      this.dataEntradaInicial = this.localDataEntradaInicial;
      this.dataEntradaFinal = this.localDataEntradaFinal;
      this.comprador = this.localComprador;
      this.$emit('change');
      this.close();
    },
    clearFilters() {
      this.localDataEntradaInicial = null;
      this.localDataEntradaFinal = null;
      this.localComprador = [];
      this.emitChange();
    },
    close() {
      this.menuOpened = false;
    },
  },
  created() {
    this.localDataEntradaInicial = this.dataEntradaInicial || null;
    this.localDataEntradaFinal = this.dataEntradaFinal || null;
    this.localComprador = this.comprador || [];
  },
};
</script>
