import { onlyNumbers } from '@/utils/regex-utils';

const createDateFilter = (intialDate, finalDate, dateField) => {
  const filters = [];
  if (intialDate) filters.push(`${dateField} >= ${intialDate}`);
  if (finalDate) filters.push(`${dateField} <= ${finalDate}`);
  return filters.length ? `(${filters.join(' and ')})` : '';
};

const getDataFilters = (state) => {
  const dataEntradaFilter = createDateFilter(state.dataEntradaInicial, state.dataEntradaFinal, 'dataRegistro');
  const prazoRespostaFilter = createDateFilter(state.prazoRespostaInicial, state.prazoRespostaFinal, 'validade');

  const dateFilters = [dataEntradaFilter, prazoRespostaFilter].filter(Boolean);

  return dateFilters.join(' and ');
};

export default {
  searchText(state) {
    return state.searchText;
  },
  selectedStatus(state) {
    return state.selectedStatus;
  },
  prazoRespostaInicial(state) {
    return state.prazoRespostaInicial;
  },
  prazoRespostaFinal(state) {
    return state.prazoRespostaFinal;
  },
  dataEntradaInicial(state) {
    return state.dataEntradaInicial;
  },
  dataEntradaFinal(state) {
    return state.dataEntradaFinal;
  },
  comprador(state) {
    return state.comprador;
  },
  status(state) {
    return state.status;
  },
  hasCustomFilters(state) {
    return [
      state.dataEntradaInicial,
      state.dataEntradaFinal,
      state.prazoRespostaInicial,
      state.prazoRespostaFinal,
      state.comprador && state.comprador.length,
      state.status && state.status.length,
    ].some((v) => !!v);
  },
  stringFilter(state) {
    const filters = ['(situacao != "RASCUNHO")'];

    const dateFilter = getDataFilters(state);
    if (dateFilter) {
      filters.push(`(${dateFilter})`);
    }

    if (state.selectedStatus && state.selectedStatus.value) {
      filters.push(`(situacao in ('${state.selectedStatus.value.join('\',\'')}'))`);
    }
    if (state.searchText) {
      const idFilter = onlyNumbers(state.searchText) ? ` or id = ${onlyNumbers(state.searchText)}` : '';
      const value = state.searchText.toLowerCase();
      filters.push(`produto.descricao like '%${value}%' or fornecedor like '%${value}%'${idFilter}`);
    }

    if (state.comprador && state.comprador.length) {
      const compradorFilter = state.comprador.map((comprador) => `(comprador.id = ${comprador.id})`);
      filters.push(`(${compradorFilter.join(' or ')})`);
    }

    if (state.status && state.status.length) {
      const statusFilter = state.status.map((status) => `(situacaoAdmin = '${status}')`);
      filters.push(`(${statusFilter.join(' or ')})`);
    }

    return filters.join(' and ');
  },
};
