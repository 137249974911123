<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="usuarios.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="usuarios.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma usuário encontrado"
      loading-text="Carregando usuários..."
      :options="optionsList"
    >
      <template v-slot:item.user.cpf="{ item }">
        {{ item.user.cpf | cpf }}
      </template>
      <template v-slot:item.user.telefone="{ item }">
        {{ item.user.telefone | phone }}
      </template>
      <template v-slot:item.user.roles="{ item }">
        <div v-for="role in item.user.roles" :key="role.id">
          {{ role.name }}
        </div>
      </template>
      <template v-slot:item.options="{ item }">
        <px-usuario-edit-roles :usuario="item.user" @update="emitUpdate">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-account-key</v-icon>
            </v-btn>
          </template>
        </px-usuario-edit-roles>
        <px-usuario-detalhes :usuario="item.user" @update="emitUpdate">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-usuario-detalhes>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="onClickDelete(item)">
              <v-icon>mdi-link-variant-remove</v-icon>
            </v-btn>
          </template>
          <span>
            Remover acesso a essa entidade
          </span>
        </v-tooltip>

      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import UsuarioService from '@/modules/entidades/usuario-service';
import UsuarioDetalhes from './UsuarioDetalhes.vue';
import UsuarioEditRoles from './UsuarioEditRoles.vue';

export default {
  name: 'ListaUsuario',
  props: {
    usuarios: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'user.nome',
          sortable: true,
        },
        {
          text: 'Email',
          value: 'user.email',
        },
        {
          text: 'Cpf',
          value: 'user.cpf',
        },
        {
          text: 'Telefone',
          value: 'user.telefone',
        },
        {
          text: 'Roles',
          value: 'user.roles',
          sortable: false,
        },
        {
          text: 'Ativo',
          value: 'user.enabled',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja deletar a conexão?',
        'Isso não removerá o usuário, apenas a conexão com a entidade!',
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.removeUserAccess(item);
        });
    },
    removeUserAccess(item) {
      UsuarioService.removerUserAccess(item.id)
        .then(() => {
          this.$toast('Acesso removido com sucesso', { color: 'success' });
          this.emitUpdate();
        });
    },
  },
  components: {
    pxUsuarioDetalhes: UsuarioDetalhes,
    pxUsuarioEditRoles: UsuarioEditRoles,
  },
};
</script>
