import { onlyNumbers } from '@/utils/regex-utils';

export default {
  searchText(state) {
    return state.searchText;
  },
  selectedStatus(state) {
    return state.selectedStatus;
  },
  prazoEntregaInicial(state) {
    return state.prazoEntregaInicial;
  },
  prazoEntregaFinal(state) {
    return state.prazoEntregaFinal;
  },
  dataEntradaInicial(state) {
    return state.dataEntradaInicial;
  },
  dataEntradaFinal(state) {
    return state.dataEntradaFinal;
  },
  comprador(state) {
    return state.comprador;
  },
  hasCustomFilters(state) {
    return [
      state.dataEntradaInicial,
      state.dataEntradaFinal,
      state.comprador && state.comprador.length,
    ].some((v) => !!v);
  },
  stringFilter(state) {
    const filters = [];

    if (state.selectedStatus && state.selectedStatus.value) {
      filters.push(`(situacao in ('${state.selectedStatus.value.join('\',\'')}'))`);
    }
    if (state.searchText) {
      const idFilter = onlyNumbers(state.searchText) ? ` or id = ${onlyNumbers(state.searchText)}` : '';
      const value = state.searchText.toLowerCase();
      filters.push(`codigoInterno like '%${value}%' or fornecedor.nome like '%${value}%'${idFilter}`);
    }

    if (state.comprador && state.comprador.length) {
      const compradorFilter = state.comprador.map((comprador) => `(comprador.id = ${comprador.id})`);
      filters.push(`(${compradorFilter.join(' or ')})`);
    }

    if (state.dataEntradaInicial) {
      filters.push(`dataRegistro >= ${state.dataEntradaInicial}`);
    }
    if (state.dataEntradaFinal) {
      filters.push(`dataRegistro <= ${state.dataEntradaFinal}`);
    }

    return filters.join(' and ');
  },
};
