<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Entidade
    </template>
    <template v-if="dialog">
      <v-row class="mt-4">
        <v-col cols="12">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="100"
            class="required"
            label="Nome"
            :error="$v.entidadeEdicao.nome.$error"
            @blur="$v.entidadeEdicao.nome.$touch()"
            v-model="entidadeEdicao.nome"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            required
            hide-details
            maxlength="10"
            class="required"
            label="Identificador"
            :error="$v.entidadeEdicao.identificador.$error"
            @blur="$v.entidadeEdicao.identificador.$touch()"
            v-model="entidadeEdicao.identificador"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            outlined
            hide-details
            label="Notificar pelo Whatsapp"
            v-model="entidadeEdicao.notificarWhatsapp"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            outlined
            hide-details
            label="Gerar compra como elaboração, antes de enviar ao fornecedor"
            v-model="entidadeEdicao.geraCompraElaboracao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            outlined
            hide-details
            label="Integração com Fluid habilitada"
            v-model="entidadeEdicao.habilitadoEventHub"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            outlined
            hide-details
            label="Controle de entrega habilitado"
            v-model="entidadeEdicao.habilitadoControleEntrega"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            outlined
            hide-details
            label="Exibir apenas meus fornecedores"
            v-model="entidadeEdicao.exibiApenasMeusFornecedores"
          />
        </v-col>
      </v-row>
      <v-row v-if="!entidadeEdicao.id">
        <v-col cols="12">
          <px-select-enum
            v-model="entidadeEdicao.tipo"
            label="Tipo"
            :items="tipos"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-select-enum v-model="entidadeEdicao.erpCamposIntegracao"
                          :items="erpCamposIntegracao"
                          label="Campos ERP"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            maxlength="200"
            label="Erp utilizado"
            v-model="entidadeEdicao.erpIntegracao"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-input-money
            ref="valorMensal"
            label="Valor Mensal (R$)"
            v-model="entidadeEdicao.valorMensal"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <px-select-enum v-model="entidadeEdicao.situacaoAdmin"
                          label="Situação" :items="statusEntidade"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-date-picker v-model="entidadeEdicao.ultimoContato"
                          label="Último contato"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            rows="3"
            label="Observações"
            counter="2500"
            v-model="entidadeEdicao.observacaoAdmin"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import { ErpCamposIntegracao } from 'px-business-components';
import StatusEntidade from '@/enums/status-entidade-enum';
import TipoEntidade from '@/enums/tipo-entidade-enum';
import EntidadeService from '../entidades-service';

function defaultEntidade() {
  return {
    nome: null,
    identificador: null,
    tipo: null,
    notificarWhatsapp: false,
    geraCompraElaboracao: false,
    habilitadoEventHub: false,
    habilitadoControleEntrega: false,
    exibiApenasMeusFornecedores: false,
    valorMensal: null,
    erpIntegracao: null,
    erpCamposIntegracao: null,
  };
}

export default {
  mixins: [validationMixin],
  props: {
    entidade: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      statusEntidade: StatusEntidade.values,
      tipos: TipoEntidade.values,
      entidadeEdicao: defaultEntidade(),
      erpCamposIntegracao: ErpCamposIntegracao.values,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    salvar() {
      this.loading = true;
      EntidadeService.save(this.entidadeEdicao)
        .then(() => {
          this.$toast('Entidade salva com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickSave() {
      if (this.validateForm()) {
        return;
      }
      this.salvar();
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.entidade) {
        EntidadeService.getEntidade(this.entidade.id)
          .then(({ data }) => {
            this.entidadeEdicao = data;
            if (this.entidadeEdicao.valorMensal) {
              this.$refs.valorMensal.$el.getElementsByTagName('input')[0].value = this.entidadeEdicao.valorMensal.toFixed(2);
            }
          });
      } else {
        this.entidadeEdicao = defaultEntidade();
      }
    },
  },
  validations: {
    entidadeEdicao: {
      nome: {
        required,
      },
      identificador: {
        required,
      },
      tipo: {
        required,
      },
    },
  },
};
</script>
