import axios from 'axios';

const getUser = () => axios.get('/user');
const getAllUsers = (params) => axios.get('/user/all', { params });
const getRoles = (params) => axios.get('/roles', { params });

export default {
  getUser,
  getAllUsers,
  getRoles,
};
