<template>
  <div>
    <px-view-header title="Cotações" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-cotacao-header :on-click-refresh="onClickRefresh"
                               :on-change-filter="onChangeFilter"
                               @change="onChangeFilter"/>
      <lista-cotacao :cotacoes="cotacoes" :loading="loading"
                     :on-change-options="onChangeOptions"
                     :options-list="optionsList"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ListaCotacao from './ListaCotacao.vue';
import ListagemCotacaoHeader from './ListagemCotacaoHeader.vue';
import CotacoesListagemService from './service/cotacoes-listagem-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      cotacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 100,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Cotações' },
      ],
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemCotacao', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.listOptions.filter = this.stringFilter;
      this.resetPage();
      this.getCotacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCotacoes();
    },
    onClickRefresh() {
      this.getCotacoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getCotacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return CotacoesListagemService.getCotacoes({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.cotacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaCotacao,
    ListagemCotacaoHeader,
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = this.stringFilter;
  },
};
</script>
