<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <px-listagem-cotacao-filtros @change="emitChange"/>
      <px-search-text label="Busque por código, comprador, fornecedor ou produto"
                      v-model="searchText"
                      style="max-width: 400px"
                      :on-search="emitChange"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="selectedStatus"
                    :on-select-item="emitChange">
    </px-line-filter>
  </div>
</template>

<script>
import ListagemCotacaoFiltros from './ListagemCotacaoFiltros.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODAS',
          description: 'Todas',
          value: null,
        },
        {
          id: 'ABERTAS',
          description: 'Abertas',
          value: ['ABERTA'],
        },
        {
          id: 'CANCELADAS',
          description: 'Canceladas',
          value: ['CANCELADA'],
        },
        {
          id: 'FINALIZADAS',
          description: 'Finalizadas',
          value: ['FINALIZADA'],
        },
      ],
    };
  },
  computed: {
    selectedStatus: {
      get() {
        return this.$store.getters['listagemCotacao/selectedStatus'];
      },
      set(selectedStatus) {
        this.$store.dispatch('listagemCotacao/selectedStatus', selectedStatus);
      },
    },
    searchText: {
      get() {
        return this.$store.getters['listagemCotacao/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCotacao/searchText', searchText);
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
  created() {
    const statusAberta = this.statusOptions.find((status) => status.id === 'ABERTAS');
    this.selectedStatus = statusAberta;
    this.emitChange();
  },
  components: {
    pxListagemCotacaoFiltros: ListagemCotacaoFiltros,
  },
};
</script>
