<template>
  <div>
    <px-view-header title="Usuários" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="voltar">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div class="mt-5">
      <listagem-usuario-header
        @update="refresh"
        :comprador="this.companyId"
        :entidade-id="entidadeId"
        :is-transportador="isTransportador"
        :on-click-refresh="onClickRefresh"
        :on-change-filter="onChangeFilter"
      />
      <lista-usuario
        @update="refresh"
        :usuarios="usuarios"
        :loading="loading"
        :on-change-options="onChangeOptions"
        :options-list="optionsList"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import UsuarioService from '../usuario-service';
import ListagemUsuarioHeader from './ListagemUsuarioHeader.vue';
import ListaUsuario from './ListaUsuario.vue';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      entidadeId: this.$route.params.entidade,
      companyId: this.$route.params.company,
      searchText: null,
      usuarios: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'email',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        {
          text: 'Negócios',
          to: '/entidades',
        },
        {
          text: 'Entidades',
          to: '/entidades',
        },
        {
          text: 'Compradores',
          to: `/entidades/${this.$route.params.entidade}/compradores`,
        },
        {
          text: 'Usuarios',
        },
      ],
      optionsList: {},
    };
  },
  computed: {
    isTransportador() {
      return this.$route.name === 'main.transportadores.usuarios';
    },
  },
  methods: {
    refresh() {
      this.getUsuarios();
    },
    voltar() {
      this.$router.push({
        name: 'main.entidades.compradores',
        params: {
          id: this.entidadeId,
        },
      });
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getUsuarios();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getUsuarios();
    },
    onClickRefresh() {
      this.getUsuarios();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getUsuarios() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return UsuarioService.getUsuarios(this.companyId, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.usuarios = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
  components: {
    ListaUsuario,
    ListagemUsuarioHeader,
  },
};
</script>
