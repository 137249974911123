<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-comprador
        @update="onUpdate"
        :entidade-id="this.entidadeId"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            Empresa compradora
          </v-btn>
        </template>
      </px-cadastro-comprador>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um comprador" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
import CadastroComprador from './CadastroComprador.vue';

export default {
  props: {
    entidadeId: {
      required: true,
    },
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    onChangeSearchText() {
      this.onChangeFilter(this.buildFilter());
    },
    onSelectItem() {
      this.onChangeFilter(this.buildFilter());
    },
    buildFilter() {
      let filter = '';
      if (this.searchText) {
        filter += `nome like '%${this.searchText.toLowerCase()}%'`;
      }
      return filter;
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  components: {
    PxCadastroComprador: CadastroComprador,
  },
};
</script>
