<template>
  <div>
    <px-view-header title="Entidades" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-entidade-header
        @update="refresh"
        :on-click-refresh="onClickRefresh"
        :on-change-filter="onChangeFilter"
      />
      <lista-entidade
        @update="refresh"
        :entidades="entidades"
        :loading="loading"
        :on-change-options="onChangeOptions"
        :options-list="optionsList"
      />
    </div>
  </div>
</template>

<script>
import { onlyNumbers } from '@/utils/regex-utils';
import paginationMixin from '@/mixins/pagination-mixin';
import EntidadeService from '../entidades-service';
import ListagemEntidadeHeader from './ListagemEntidadesHeader.vue';
import ListaEntidade from './ListaEntidade.vue';

export default {
  components: {
    ListaEntidade,
    ListagemEntidadeHeader,
  },
  mixins: [paginationMixin],
  methods: {
    refresh() {
      this.getEntidades();
    },
    buildCnpjQuery(filter) {
      const cnpj = onlyNumbers(filter);
      if (!cnpj) {
        return undefined;
      }
      return cnpj;
    },
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getEntidades();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getEntidades();
    },
    onClickRefresh() {
      this.getEntidades();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getEntidades() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return EntidadeService.getEntidades({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
        cnpj: this.buildCnpjQuery(this.listOptions.filter),
      })
        .then((response) => {
          this.entidades = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      searchText: null,
      entidades: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 25,
        offset: 0,
        filter: '',
        sort: 'nome',
      },
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Entidades' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
