<template>
  <px-custom-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Usuário
    </template>
    <template v-if="dialog">
      <v-row class="mt-5">
        <v-col cols="12">
          <px-autocomplete-user required
                                class="required"
                                :error="$v.usuario.$error"
                                @blur="$v.usuario.$touch()"
                                v-model="usuario"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             @click="salvar">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import UsuarioService from '@/modules/entidades/usuario-service';

export default {
  props: {
    comprador: {
      type: [Number, String],
      required: true,
    },
    entidadeId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      usuario: null,
    };
  },
  methods: {
    salvar() {
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      UsuarioService.vincularUsuario({
        idEntidade: this.entidadeId,
        idCompany: this.comprador,
        idUser: this.usuario.id,
      })
        .then(() => {
          this.$toast('Usuário vinculado com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.usuario = null;
    },
  },
  validations: {
    usuario: {
      required,
    },
  },
};
</script>
