import {
  SET_SEARCH_TEXT_ACTION,
  SET_SELECTED_STATUS_ACTION,
  SET_COMPRADOR_ACTION,
  SET_DATA_ENTRADA_INICIAL_ACTION,
  SET_DATA_ENTRADA_FINAL_ACTION,
} from './actions.type';
import {
  SET_SEARCH_TEXT,
  SET_SELECTED_STATUS,
  SET_COMPRADOR,
  SET_DATA_ENTRADA_INICIAL,
  SET_DATA_ENTRADA_FINAL,
} from './mutations.type';

export default {
  [SET_SEARCH_TEXT_ACTION](context, text) {
    context.commit(SET_SEARCH_TEXT, text);
  },
  [SET_SELECTED_STATUS_ACTION](context, status) {
    context.commit(SET_SELECTED_STATUS, status);
  },
  [SET_COMPRADOR_ACTION](context, comprador) {
    context.commit(SET_COMPRADOR, comprador);
  },
  [SET_DATA_ENTRADA_INICIAL_ACTION](context, dataEntradaInicial) {
    context.commit(SET_DATA_ENTRADA_INICIAL, dataEntradaInicial);
  },
  [SET_DATA_ENTRADA_FINAL_ACTION](context, dataEntradaFinal) {
    context.commit(SET_DATA_ENTRADA_FINAL, dataEntradaFinal);
  },
};
